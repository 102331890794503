// feature
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { sortedPages } from '@/feature/sortedPages'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// interface
import { ChangeEvent } from 'react'

// components
import Layout from '@/components/Layout'
import { AttentionContainer } from '@/components/AttentionContainer'
import { CaseCard } from '@/components/CaseCard'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { InputButton } from '@/components/InputButton'
import { ResponsiveImage } from '@/components/ResponsiveImage'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { AnchorAdjuster, BreakPointHiddenBr } from '@/components/Utils'

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      casePages: edges {
        casePage: node {
          id
          slug
          frontmatter {
            cardSize
            caseTags
            caseType
            date(formatString: "YYYY.MM.DD")
            title
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { casePages } = props.data.allMdx
  const { sortedPagesByCardSize } = sortedPages(casePages)

  const selectedLargeImageCase = sortedPagesByCardSize.filter(
    item => item.casePage.frontmatter.cardSize === 'large',
  )

  // OPTIMIZE: 最大表示数の判定をもう少しすっきりさせたい
  let caseCount = 0

  const isShowCase = (articleCaseTags: string[]): boolean => {
    const selectedLargeImageCaseLength = selectedLargeImageCase.length

    // NOTE: 最大表示数を 4 とする
    if (caseCount >= 4) {
      return false
    }

    for (let index = 0; index < selectedLargeImageCaseLength; index++) {
      const hasTag = articleCaseTags.includes('情報機器事業部')

      if (!hasTag) {
        return false
      }
    }

    caseCount++
    return true
  }

  const routingSelectedCaseTags = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.currentTarget.checked

    props.clearCaseFilter()

    if (isChecked) {
      props.addCaseFilter(event)
    }

    navigate('/case/')
  }

  return (
    <Layout>
      <Seo page="it-detail" />
      <ItDetail>
        <LayoutContainer>
          <HeadingLevel1 subHeading="情報機器事業部" note="flow">
            製品が出来る
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            までの流れ
          </HeadingLevel1>
          <Flow>
            <FlowOrderedList>
              <li>
                <AnchorAdjuster id="flow-1"></AnchorAdjuster>
                <FlowIcon>
                  <img
                    src="/images/service/icon_speech-bubble.svg"
                    alt=""
                    width="186"
                    height="186"
                  />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">要件聞き取り</HeadingLevel2>
                  <FlowTextInner>
                    <p>
                      お客様の「あったらいいな」から始まります。
                      <br />
                      お客様と一緒に課題に取組み、経験豊富なスタッフが最適なご提案を致します。
                    </p>
                  </FlowTextInner>
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>機構や制御等、全て社内で統一して形にしていきます。</li>
                            <li>
                              培ったペーパーハンドリング技術を生かし、お客様の「あったらいいな」を実現できるように形にします。
                            </li>
                            <li>お客様のご要望を、ハッキリとした形へ実現します。</li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-2"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_hearing.svg" alt="" width="186" height="186" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">仕様説明会</HeadingLevel2>
                  <FlowTextInner>
                    <p>
                      弊社では仕様説明会を『オープニング』と呼んでいます。
                      <br />
                      この『オープニング』にて製品開発がスタートします。
                      <br />
                      『オープニング』では各部署のメンバーが参加し、情報共有をおこないます。
                    </p>
                  </FlowTextInner>
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>お客様の『あったらいいな』をメンバーで共有します。</li>
                            <li>満足いただける製品になるよう、皆で知恵を出し合います。</li>
                            <li>製品完成までのベクトルを合わせます。</li>
                            <li>役割を明確にし、抜けが無いようにプロセスをチェック。</li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-3"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_monitor.svg" alt="" width="186" height="186" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">設計</HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/it/detail/img_01.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      設計担当者が主となり装置の要求仕様から機械・電気・制御担当がお互いの構想を擦り合わせながら、機構のイメージ・電気部品選定・制御構想を行います。
                      <br />
                      また必要であれば構想レビューを行い、案件に関わる部署のメンバーと情報を共有をします。
                      <br />
                      構想終了後、設計（計画）に入ります。
                      <br />
                      ここでも設計（計画）中間時点、終了時点　都度に合わせてDR（デザインレビュー）を行う事で、上席者、有識者の助言も仰ぎながら機構の確認や選定部品の妥当性を確認し、出戻り工程を防ぎます。
                      <br />
                      DRレビュー終了後は、各部品展開（バラシ）～出図の流れとなります。
                    </p>
                  </FlowTextInner>
                  <ResponsiveImage src="/images/service/it/detail/img_design-flow_sm.png" alt="" />
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>構想は設計の第一歩。機械・電気・制御設計、一緒に構想</li>
                            <li>
                              培ったペーパーハンドリング技術を生かし、お客様の『あったらいいな』をハッキリとした形に
                            </li>
                            <li>仕様・ご要望内容と違いが無いか？デザインレビューでチェック</li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-4"></AnchorAdjuster>
                <FlowIcon>
                  <img
                    src="/images/service/icon_screw-driver.svg"
                    alt=""
                    width="186"
                    height="186"
                  />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">製作</HeadingLevel2>
                  <FlowTextInner>
                    <p>
                      設計で作成した図面は出図を経て、部品加工～組立となります。
                      <br />
                      弊社と長年お付き合い頂いている優れた加工先や組立先にて品質の良い製品を作って頂いています。
                      <br />
                      設計したものが、形になる過程での加工先や組立先から問い合わせも設計部隊と製造・調達部隊が連携し、即座に対応します。
                      <br />
                      場合によっては加工先、組立先に出向いて品質（仕上がり）、組立（進捗）の確認や指導を行います。
                    </p>
                  </FlowTextInner>
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>
                              設計部隊と製造・調達部隊が連携し、加工先、組立先からの問い合わせもタイムリーに対応
                            </li>
                            <li>
                              協力会社に出向いて、品質チェック。お客様の『あったらいいな』を確認します。
                            </li>
                            <li>組立先にて複数の部品が組み立てられ、形になっていきます。</li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-5"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_wrench.svg" alt="" width="186" height="186" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">調整</HeadingLevel2>
                  <FlowTextInner>
                    <FlowImgContainer>
                      <RoundedCornersContainer>
                        <img
                          src="/images/service/it/detail/img_02.jpg"
                          alt=""
                          width="254"
                          height="164"
                        />
                      </RoundedCornersContainer>
                    </FlowImgContainer>
                    <p>
                      協力会社にて組立・配線された装置を安定して動作するよう調整を行います。
                      <br />
                      お客様のご要望に沿った一品一様で、開発・製造する装置も多く、構想通りに出来上がっているか？不具合は無いか？を十分にチェックを行い、調整を進めて行きます。
                      <br />
                      その後最終検査～妥当性確認・お客様検収を経て出荷段取りとなります。
                    </p>
                  </FlowTextInner>
                  <ResponsiveImage src="/images/service/it/detail/img_adjustment_sm.png" alt="" />
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>
                              経験を活かしお客様の『あったらいいな』を実現する。ツメの工程です。
                            </li>
                            <li>一品ものは、技術者が安定して動作するよう確かな目で調整します</li>
                            <li>
                              妥当性確認で合格した製品は、お客様にて確認をお願いします。
                              <br />
                              実現した『あったらいいな』を確認して下さい。
                            </li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
              <li>
                <AnchorAdjuster id="flow-6"></AnchorAdjuster>
                <FlowIcon>
                  <img src="/images/service/icon_truck.svg" alt="" width="186" height="186" />
                </FlowIcon>
                <FlowTextContainer>
                  <HeadingLevel2 color="theme">納品</HeadingLevel2>
                  <FlowTextInner>
                    <p>
                      案件によって、担当者も装置と一緒にお伺いします。
                      <br />
                      搬入・設置～立上・動作確認～操作説明～お客様検収～引き渡しとなります。
                      <br />
                      引き渡し後は、お客様のご運用まで、しっかりとサポートします。
                    </p>
                  </FlowTextInner>
                  <ResponsiveImage
                    src="/images/service/it/detail/img_delivery-of-materials_sm.png"
                    alt=""
                  />
                  <FlowPointContainer>
                    <RoundedCornersContainer>
                      <AttentionContainer>
                        <FlowPointListContainer>
                          <FlowPointIcon>
                            <img
                              src="/images/service/it/icon_point.svg"
                              alt=""
                              width="86"
                              height="86"
                            />
                          </FlowPointIcon>
                          <FlowPointList>
                            <li>
                              『あったらいいな』の詰まった装置を、お客様のもとへお届けします。
                            </li>
                            <li>
                              技術担当者がお伺いし、立上～動作確認、使い方をしっかりご説明します。
                            </li>
                            <li>ご運用もサポート。『あったらいいな』を『お客様の楽』へ</li>
                            <li>
                              ご導入後、「もっと、あったらいいな」もご相談下さい。しっかりサポートします。
                            </li>
                          </FlowPointList>
                        </FlowPointListContainer>
                      </AttentionContainer>
                    </RoundedCornersContainer>
                  </FlowPointContainer>
                </FlowTextContainer>
              </li>
            </FlowOrderedList>
          </Flow>
        </LayoutContainer>
        <LayoutContainer>
          <Case>
            <HeadingLevel2 note="works">情報機器事業部の事例</HeadingLevel2>
            <CaseCardList>
              {selectedLargeImageCase.map(({ casePage }) => {
                return (
                  isShowCase(casePage.frontmatter.caseTags) && (
                    <CaseCardListItem cardSize={casePage.frontmatter.cardSize} key={casePage.id}>
                      <CaseCard
                        link={`/case/${casePage.slug}/`}
                        heading={casePage.frontmatter.title}
                        imgSrc={`/images/case/${casePage.slug}/1.jpg`}
                        caseType={casePage.frontmatter.caseType}
                        cardSize={casePage.frontmatter.cardSize}
                      />
                    </CaseCardListItem>
                  )
                )
              })}
            </CaseCardList>
            <InputButtonContainer>
              <InputButton
                onChange={event => routingSelectedCaseTags(event)}
                value={metaData.it.title}
                checked={props.selectedCaseTags.includes(metaData.it.title)}
                label={`${metaData.it.title}の事例はこちら`}
                color="theme"
              ></InputButton>
            </InputButtonContainer>
          </Case>
        </LayoutContainer>
      </ItDetail>
    </Layout>
  )
}

const ItDetail = styled.div``

const Flow = styled.section`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: 0;
    right: 0;
    width: 57.7rem;
    height: 64rem;
  }
`

const FlowOrderedList = styled.ol`
  ${breakpointDown('sm')} {
    margin-top: 4.8rem;
  }

  ${breakpointUp('md')} {
    margin-top: 11rem;
  }

  > li {
    ${breakpointUp('md')} {
      display: flex;
      position: relative;

      &:not(:last-of-type) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 14.4rem;
          left: 9.2rem;
          width: 0.2rem;
          height: calc(100% - 7rem);
          background-color: ${colorConfig.borderColor};
        }
      }
    }

    + li {
      ${breakpointDown('sm')} {
        margin-top: 5.6rem;
      }

      ${breakpointUp('md')} {
        margin-top: 10rem;
      }
    }
  }
`

const FlowIcon = styled.div`
  ${breakpointDown('sm')} {
    width: 11.8rem;
    margin-left: -1.4rem;
  }

  ${breakpointUp('md')} {
    flex-basis: 18.6rem;
    flex-shrink: 0;
    margin-top: -3.6rem;
    margin-right: 7rem;
  }
`

const FlowTextContainer = styled.div`
  width: 100%;

  ${breakpointDown('sm')} {
    margin-top: -1rem;
  }
`

const FlowPointContainer = styled.div`
  ${breakpointDown('sm')} {
    margin-top: 2.8rem;
  }

  ${breakpointUp('md')} {
    margin-top: 6rem;
  }
`

const FlowPointListContainer = styled.div`
  ${breakpointUp('md')} {
    display: flex;
  }
`

const FlowPointList = styled.ul`
  list-style: disc inside;
  text-indent: -2rem;
  margin-left: 3rem;

  ${breakpointUp('md')} {
    margin-left: 5rem;
  }
`

const FlowImgContainer = styled.div`
  ${breakpointDown('sm')} {
    margin-bottom: 2rem;
  }

  ${breakpointUp('md')} {
    float: right;
    flex-shrink: 0;
    margin-left: 2rem;
    width: 25.4rem;
  }
`

const FlowTextInner = styled.div`
  ${breakpointDown('sm')} {
    margin-top: 2rem;
    margin-bottom: 2.8rem;
  }

  ${breakpointUp('md')} {
    margin-top: 4rem;
    margin-bottom: 4.8rem;
  }
`

const FlowPointIcon = styled.div`
  ${breakpointDown('sm')} {
    width: 6rem;
    margin: 0 auto 1rem;
  }

  ${breakpointUp('md')} {
    flex-shrink: 0;
    width: 8.6rem;
  }
`

const Case = styled.section`
  margin: 8rem 0;

  ${breakpointUp('md')} {
    margin: 10rem 0;
  }
`

const CaseCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
  margin-top: 4rem;

  ${breakpointUp('md')} {
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: ${mixins.vw({ size: 36 })} ${mixins.vw({ size: 24 })};
  }

  ${breakpointUp('lg')} {
    gap: 3.6rem 2.4rem;
  }
`

const CaseCardListItem = styled.li<{ cardSize: string }>`
  ${({ cardSize }) =>
    cardSize === 'large'
      ? css`
          grid-row: span 2;
          grid-column: span 2;
        `
      : css`
          width: 100%;
        `}
`

const InputButtonContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
