import { VFC } from 'react'
import { ResponsiveImageProps } from '@/components/types'

export const ResponsiveImage: VFC<ResponsiveImageProps> = ({ src, alt }) => {
  const lgImageSrc = (): string => {
    return src.replace(/_sm.(gif|jpe?g|png|svg)/, '_lg.$1')
  }

  return (
    <picture>
      <source srcSet={lgImageSrc()} media="(min-width: 768px)" />
      <img src={src} alt={alt} />
    </picture>
  )
}
